
import { defineComponent } from 'vue'
import MoveMasterProductOfTransfer from '@/components/storage/MoveMasterProductOfTransfer.vue'

export default defineComponent({
	name: 'ToTransferProduct',

	components: { MoveMasterProductOfTransfer },

	setup() {
		return {}
	}
})
