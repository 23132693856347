
import { defineComponent, onBeforeMount, ref } from 'vue'
import MoveMaster from '@/components/storage/MoveMaster.vue'
import { IReqCountVarInStorage, IResCountVarInStorage } from '@/components/storage/MoveMasterProductTransferWrap.vue'
import axios from '@/http'

export default defineComponent({
	name: 'MoveMasterProductOfTransfer',

	components: { MoveMaster },

	setup() {
		const countInTransfer = ref(0)

		async function checkCountVarInStorage(
			cellsId: Array<number>,
			variationsId: Array<number> = []
		): Promise<IResCountVarInStorage | null> {
			const data: IReqCountVarInStorage = {
				variationsId,
				cellsId
			}

			try {
				return (await axios.post<IResCountVarInStorage>('/storage/get-count-variation-storage-by-cell-id', data)).data
			} catch (e) {
				return null
			}
		}

		async function success() {
			countInTransfer.value = (await checkCountVarInStorage([1]))?.count || 0
		}

		onBeforeMount(async () => {
			countInTransfer.value = (await checkCountVarInStorage([1]))?.count || 0
		})

		return {
			countInTransfer,

			success
		}
	}
})
