import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoveMaster = _resolveComponent("MoveMaster")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", null, "Осталось " + _toDisplayString(_ctx.countInTransfer), 1),
    _createVNode(_component_MoveMaster, {
      "prop-options": { source: 1, type: 'h-q' },
      urlSet: "storage/set-of-transfer",
      onSuccessMoveMaster: _ctx.success
    }, null, 8, ["onSuccessMoveMaster"])
  ], 64))
}